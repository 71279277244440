<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Couche : {{couche.nom}}</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                    <li class="breadcrumb-item active">Couche</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
   <div class="container">

    
    <div class="row ">
      <div class="col-lg-7 mx-auto">
        <div class="card mt-2 mx-auto p-4 bg-light">
            <div class="card-body bg-light">
       
            <div class = "container">
                             <form id="contact-form" role="form" >

            <div class="controls">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_name">Nom *</label>
                            <input disabled [value]="couche.nom" id="form_nom" type="text" name="nom" class="form-control" placeholder="Entrez le nom de la couche *" required="required" data-error="Le nom est requis">
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Nom Anglais *</label>
                            <input disabled [value]="couche.nom_en"  id="form_nom_en" type="text" name="nom_en" class="form-control" placeholder="Entrez le nom de la couche en anglais *" required="required" data-error="Le nom anglais est requis">
                                                            </div>
                    </div>
                     
                     <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Thematique *</label>
                            <input disabled [value]="couche.sousThematique[0].thematique[0].nom"   type="text"  class="form-control" >
                                                            </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Sous-Thématique *</label>
                            <input disabled [value]="couche.sousThematique[0].nom"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Geometrie *</label>
                            <input disabled [value]="couche.geometry"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Service Carto *</label>
                            <input disabled [value]="couche.service_carto"   type="text"  class="form-control" >
                                                            </div>
                    </div>


                   

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Projection *</label>
                            <input disabled [value]="couche.projection"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Nombre Features *</label>
                            <input disabled [value]="couche.number_features"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Surface *</label>
                            <input disabled [value]="couche.surface"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Distance *</label>
                            <input disabled [value]="couche.distance"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Resumé *</label>
                            <input disabled [value]="couche.metadatas.resume"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Description *</label>
                            <input disabled [value]="couche.metadatas.description"   type="text"  class="form-control" >
                                                            </div>
                    </div>

                    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_color">Couleur *</label>
                           <p-colorPicker [disabled]="true" name="color"   [(ngModel)]="couche.remplir_color"></p-colorPicker>
                            
                        </div>
                    </div>
                  
                </div>
           

                <div>
                    <label for="form_image">Logo *</label>
  <img height="200" width="200" alt="Logo" [src]="environment.apiUrl + couche.logo" class="preview">
</div>


        </div>
         </form>
        </div>
            </div>


    </div>
        <!-- /.8 -->

    </div>
    <!-- /.row-->

</div>
</div>

</section>
<!-- /.content -->
