import { AddCoucheInterface } from '@/interfaces/addcouche';
import { SousThematique } from '@/interfaces/sousthematiques';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CouchesService } from '@services/couches.service';
import { SousthematiquesService } from '@services/sousthematiques.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create.couche',
  templateUrl: './create.couche.component.html',
  styleUrls: ['./create.couche.component.scss']
})
export class CreateCoucheComponent implements OnInit {
  geometries = [{ nom: 'point' }, { nom: 'line' }, { nom: 'polygon' }];

  services_carto = [{ nom: 'wms' }, { nom: 'wfs' }];
  wms_types = [{ nom: 'data' }, { nom: 'osm' }, { nom: 'qgis' }];

  operateurs = [
    { id: '0', signe: '=' },
    { id: '1', signe: '!=' },
    { id: '2', signe: 'is not null' },
    { id: '3', signe: 'is null' }
  ];
  public coucheForm: UntypedFormGroup;
  color = '#d41c31';
  selectColor;
  public isCoucheLoading = false;

  selectedFiles?: FileList;
  currentFile?: File;

  selectedDataFiles?: FileList;
  currentDataFile?: File;

  selectedQmlFiles?: FileList;
  currentQmlFile?: File;

  progress = 0;
  message = '';
  preview = 'assets/img/defaut.png';

  imageInfos?: Observable<any>;
  sousthematiques: SousThematique[];

  activeOSM = false;
  activeData = false;

  ngOnInit(): void {
    this.sousthematiques = this.sousthematiquesService.getSousThematiques().data.sous_thematiques;
    this.coucheForm = new UntypedFormGroup({
      nom: new UntypedFormControl(null, Validators.required),
      nom_en: new UntypedFormControl(null, Validators.required),
      sousthematiques: new UntypedFormControl(null, Validators.required),
      geometry: new UntypedFormControl(null, Validators.required),
      service_carto: new UntypedFormControl(null, Validators.required),
      wms_type: new UntypedFormControl(null, Validators.required),
      operateur: new UntypedFormControl(null, Validators.nullValidator),
      condition: new UntypedFormControl(null, Validators.nullValidator),
      key: new UntypedFormControl(null, Validators.nullValidator),
      value: new UntypedFormControl(null, Validators.nullValidator),
      description: new UntypedFormControl(null, Validators.nullValidator)
    });
  }

  constructor(
    public sousthematiquesService: SousthematiquesService,
    public couchesService: CouchesService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  selectDataFile(event: any): void {
    this.selectedDataFiles = event.target.files;

    if (this.selectedDataFiles) {
      const file: File | null = this.selectedDataFiles.item(0);

      if (file) {
        this.currentDataFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
        };

        reader.readAsDataURL(this.currentDataFile);
      }
    }
  }

  selectQmlFile(event: any): void {
    this.selectedQmlFiles = event.target.files;

    if (this.selectedQmlFiles) {
      const file: File | null = this.selectedQmlFiles.item(0);

      if (file) {
        this.currentQmlFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
        };

        reader.readAsDataURL(this.currentQmlFile);
      }
    }
  }

  async addCouche() {
    const file: File | null = this.selectedFiles.item(0);
    let data: File | null = null;
    let qml: File | null = null;
    const formData = new FormData();

    if (this.selectedDataFiles) {
      data = this.selectedDataFiles.item(0);
      formData.append('data_src', data, data.name);
    }

    if (this.selectedQmlFiles) {
      qml = this.selectedQmlFiles.item(0);
      formData.append('data_qml', qml, qml.name);
    }

    if (this.coucheForm.valid) {
      this.isCoucheLoading = true;
      formData.append('logo', file, file.name);
      formData.append('nom', this.coucheForm.value.nom);
      formData.append('nom_en', this.coucheForm.value.nom_en);
      formData.append('sous_thematique_id', this.coucheForm.value.sousthematiques);
      formData.append('geometry', this.coucheForm.value.geometry);
      formData.append('remplir_color', this.selectColor);
      formData.append('contour_color', this.selectColor);
      formData.append('service_carto', this.coucheForm.value.service_carto);
      formData.append('wms_type', this.coucheForm.value.wms_type);
      formData.append('condition', this.coucheForm.value.condition);
      formData.append('key', this.coucheForm.value.key);
      formData.append('value', this.coucheForm.value.value);
      formData.append('operateur', '0');
      await this.couchesService
        .addCouche(formData, this.coucheForm.value.description)
        .then((response: { error: boolean; msg?: string; }) => {
          if (response.error) {
            this.toastr.error('Echec de Création');
          } else {
            this.toastr.success('Création de la couche réussie');
            this.router.navigate(['/couches']);
          }
        });
      this.isCoucheLoading = false;
    } else {
      this.toastr.error('Formulaire non valide');
    }
  }

  updateColor(event: any) {
    this.selectColor = event.value;
  }

  onSelect(val) {
    if (val == 'osm') {
      this.activeOSM = true;
      this.activeData = false;
    } else if (val == 'data') {
      this.activeData = true;
      this.activeOSM = false;
    } else {
      this.activeData = false;
      this.activeOSM = false;
    }
  }
}
