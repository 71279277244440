<!-- Content Header (Page header) -->
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Nouvelle Couche</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Accueil</a></li>
                    <li class="breadcrumb-item active">Nouvelle Couche</li>
                </ol>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
   <div class="container">

    
    <div class="row ">
      <div class="col-lg-7 mx-auto">
        <div class="card mt-2 mx-auto p-4 bg-light">
            <div class="card-body bg-light">
       
            <div class = "container">
                             <form id="contact-form" role="form" [formGroup]="coucheForm" (ngSubmit)="addCouche()" >

            <div class="controls">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_name">Nom *</label>
                            <input formControlName="nom" id="form_nom" type="text" name="nom" class="form-control" placeholder="Entrez le nom de la couche *" required="required" data-error="Le nom est requis">
                            
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_lastname">Nom Anglais *</label>
                            <input formControlName="nom_en" id="form_nom_en" type="text" name="nom_en" class="form-control" placeholder="Entrez le nom de la couche en anglais *" required="required" data-error="Le nom anglais est requis">
                                                            </div>
                    </div>
                </div>

                  <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_description">Description de la couche *</label>
                            <textarea formControlName="description" id="form_description" type="text" name="description" class="form-control" placeholder="Entrez la description de la couche" ></textarea>
                                                            </div>
                    </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="form_color">Couleur *</label>
                            <p-colorPicker (onChange)="updateColor($event)" name="color"   [(ngModel)]="color"></p-colorPicker>
                            
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                           
                             <label for="sousthematiques">Sous-Thématiques</label>
  <select class="form-control" formControlName="sousthematiques" id="sousthematiques">
    <option [ngValue]="null" disabled>Choisir une sous-thématique</option>
    <option *ngFor="let sousthematique of sousthematiques ; let i = index" [value]="sousthematique.id">
      {{sousthematique.nom}}
    </option>
  </select>
                            
                        </div>
                    </div>
                  
                </div>

                <div class="row">

                       <div class="col-md-6">
                        <div class="form-group">
                           
                             <label for="geometry">Geometrie</label>
  <select class="form-control" formControlName="geometry" id="geometry">
    <option [ngValue]="null" disabled>Choisir une Geometry</option>
    <option *ngFor="let geometry of geometries ; let i = index" [value]="geometry.nom">
      {{geometry.nom}}
    </option>
  </select>
                            
                        </div>
                    </div>

                          <div class="col-md-6">
                        <div class="form-group">
                           
                             <label for="service_carto">Service Cartographique</label>
  <select class="form-control" formControlName="service_carto" id="service_carto">
    <option [ngValue]="null" disabled>Choisir un service</option>
    <option *ngFor="let service_carto of services_carto ; let i = index" [value]="service_carto.nom">
      {{service_carto.nom}}
    </option>
  </select>
                            
                        </div>
                    </div>
                </div>
                  


                           <div class="col-md-6">
                        <div class="form-group">
                           
                             <label for="wms_type">Source de Données</label>
  <select (change)="onSelect(val.value)"  class="form-control" formControlName="wms_type" id="wms_type" #val  >
    <option [ngValue]="null" disabled>Choisir une source</option>
    <option *ngFor="let wms_type of wms_types ; let i = index" [value]="wms_type.nom">
      {{wms_type.nom}}
    </option>
  </select>
                            
                        </div>
                    </div>
<div *ngIf="activeOSM" class="row">
                     <div class="form-group">
                            <label for="form_condition">Condition OSM </label>
                            <input formControlName="condition" id="form_condition" type="text" name="condition" class="form-control" placeholder="Condition OSM(OR ou AND)"  >
                                                            </div>
                </div>

                <div *ngIf="activeOSM" class="row">
                     <div class="form-group">
                            <label for="form_key">Key OSM </label>
                            <input  formControlName="key" id="form_key" type="text" name="key" class="form-control" placeholder="Key OSM"  >
                                                            </div>
                </div>

                <div *ngIf="activeOSM" class="row">
                     <div class="form-group">
                            <label for="form_value">Value OSM </label>
                            <input  formControlName="value" id="form_value" type="text" name="value" class="form-control" placeholder="Value OSM"  >
                                                            </div>
                </div>
                    
                      <div *ngIf="activeOSM" class="col-md-6">
                        <div class="form-group">
                           
                             <label for="operateur">Opérateur OSM</label>
  <select  class="form-control" formControlName="operateur" id="operateur">
    <option [ngValue]="null" disabled>Choisir un Opérateur</option>
    <option *ngFor="let operateur of operateurs ; let i = index" [value]="operateur.id">
      {{operateur.signe}}
    </option>
  </select>
                            
                        </div>
                    </div>

                
                </div>

   <div *ngIf="activeData" class="row">
                    <div class="col-8">
    <label class="btn btn-default p-0">
        <label for="form_data_src">Choisir une source de donnée </label>
      <input  type="file" accept=".geojson,.kml,.gpkg,.zip" (change)="selectDataFile($event)" />
    </label>
  </div>

          
                </div>

                  <div class="row">
                    <div class="col-8">
    <label class="btn btn-default p-0">
        <label for="form_data_qml">Choisir un fichier de style </label>
      <input  type="file" accept=".qml" (change)="selectQmlFile($event)" />
    </label>
  </div>

          
                </div>


                <div class="row">
                    <div class="col-8">
    <label class="btn btn-default p-0">
        <label for="form_image">Choisir un logo *</label>
      <input type="file" accept="image/*" (change)="selectFile($event)" />
    </label>
  </div>

          
                </div>

                <div>
  <img height="200" width="200" alt="Logo" [src]="preview" class="preview">
</div>

  <div class="col-md-12">
                        
                       <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isCoucheLoading"
                    >
                        Creer la Couche
                    </pf-button>
                    
                </div>


         </form>
        </div>
            </div>


    </div>
        <!-- /.8 -->

    </div>
    <!-- /.row-->

</div>
</div>

</section>
<!-- /.content -->
